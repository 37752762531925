<template>
  <div class="recordings-list-wrapper">
    <div class="recordings-list-header">
      <span>{{$t('dashboard.recordings.recent_recordings')}}</span>
      <div class="search">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.11" height="22.129" viewBox="0 0 22.11 22.129" class="magnifier">
          <g fill="none" stroke="#383838" stroke-miterlimit="10" transform="translate(.5 .5)">
            <circle cx="9" cy="9" r="9"></circle>
            <path stroke-linecap="square" d="M0 0l5.4 5.422" transform="translate(15.5 15.5)"></path>
          </g>
        </svg>
        <input type="text" :placeholder="$t('dashboard.recordings.search_for_recordings')" v-model="searchFilter">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="clear-filter" v-if="searchFilter.length > 0" @click="searchFilter = '';hideColumns()">
          <path d="M24 1.414L22.586 0 12 10.586 1.414 0 0 1.414 10.586 12 0 22.586 1.414 24 12 13.414 22.586 24 24 22.586 13.414 12z"></path>
        </svg>
      </div>
      <button :class="['delete', {'disabled': highlightedRecordsIds.length === 0}]" @click="showDeleteModal = true">{{$t('dashboard.recordings.delete')}}</button>
      <button class="download-multiple" @click="downloadMultiple()" v-if="highlightedRecordsIds.length > 1">{{$t('navbar.download')}}</button>
      <img src="@/assets/images/filter-list-icon.svg" :alt="$t('dashboard.recordings.filter')" @click="setMobileShowFilterLists(!getMobileShowFilterLists())" id="toggleLists">
    </div>
    <div class="mobile-recordings" v-if="paginatedRecordings.length > 0">
      <div v-for="record in paginatedRecordings" :key="record.id" class="mobile-record-wrapper">
        <div class="record-date">
          <span>{{getDate(record.created)}}</span>
          <span>{{getTime(record.created)}}</span>
        </div>
        <div class="record">
          <img src="@/assets/images/more-dark.svg" :alt="$t('dashboard.invoices.more')" id="more" :class="{'rotate' : expandedRow && expandedRow.id === record.id}" @click="expandRow($event, record)">
          <p class="record-name" @click="expandRow($event, record)">{{ record.user_label ? record.user_label : record.id  }}</p>
          <p class="record-number">{{ record.number }}</p>
          <div class="record-info">
            <span class="badge transcribed" v-if="record.transcription && record.transcription.status === 'completed'">{{$t('dashboard.header.transcribed')}}</span>
            <span class="badge" v-else>{{$t('dashboard.recordings.not')}} {{$t('dashboard.header.transcribed')}}</span>
            <div class="flex" v-if="!record.meeting.id">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.11003 10.8643C4.88673 11.6397 5.70658 12.3398 6.56958 12.9645C7.43258 13.5891 8.29558 14.0845 9.15858 14.4507C10.0216 14.8169 10.8387 15 11.61 15C12.1332 15 12.6214 14.9058 13.0744 14.7173C13.5275 14.5288 13.9374 14.2326 14.3042 13.8288C14.52 13.5918 14.6899 13.336 14.8139 13.0614C14.938 12.7921 15 12.5202 15 12.2456C15 12.0409 14.9595 11.8471 14.8786 11.664C14.7977 11.4755 14.6602 11.3139 14.466 11.1793L11.9822 9.41842C11.7934 9.28918 11.6181 9.19224 11.4563 9.12762C11.2945 9.063 11.1408 9.03069 10.9951 9.03069C10.8118 9.03069 10.6338 9.08185 10.4612 9.18417C10.2886 9.2811 10.1106 9.42111 9.92718 9.6042L9.35275 10.1696C9.27185 10.2558 9.16936 10.2989 9.04531 10.2989C8.98058 10.2989 8.91855 10.2881 8.85922 10.2666C8.80529 10.245 8.75944 10.2262 8.72168 10.21C8.46818 10.0754 8.14455 9.84383 7.75081 9.51535C7.35707 9.18148 6.96063 8.81529 6.56149 8.4168C6.16775 8.01831 5.80367 7.62251 5.46926 7.2294C5.13484 6.8363 4.90291 6.51589 4.77346 6.26817C4.75189 6.22509 4.73031 6.17663 4.70874 6.12278C4.69256 6.06354 4.68447 6.00431 4.68447 5.94507C4.68447 5.8266 4.72492 5.72698 4.80583 5.6462L5.38026 5.05654C5.55825 4.87345 5.69849 4.69575 5.80097 4.52342C5.90345 4.3511 5.95469 4.1734 5.95469 3.99031C5.95469 3.84491 5.91963 3.69144 5.84951 3.52989C5.78479 3.36834 5.68501 3.19332 5.55016 3.00485L3.81068 0.557351C3.67044 0.36349 3.50324 0.223479 3.30906 0.137318C3.11489 0.0457727 2.90992 0 2.69417 0C2.13862 0 1.61812 0.234249 1.13269 0.702746C0.738943 1.0797 0.450378 1.49704 0.26699 1.95477C0.0889968 2.40711 0 2.89176 0 3.40872C0 4.18417 0.18069 5 0.542071 5.85622C0.903452 6.70705 1.39428 7.56058 2.01456 8.4168C2.63484 9.27302 3.33333 10.0889 4.11003 10.8643Z" fill="#787885"/>
              </svg>
              <span class="call-type">{{$t('dashboard.recordings.call')}}</span>
            </div>
            <div class="flex" v-if="record.meeting.id">
              <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.04543 1C5.04543 0.723858 4.82157 0.5 4.54543 0.5C4.26929 0.5 4.04543 0.723858 4.04543 1H5.04543ZM4.04543 3.52248C4.04543 3.79862 4.26929 4.02248 4.54543 4.02248C4.82157 4.02248 5.04543 3.79862 5.04543 3.52248H4.04543ZM10.9546 1C10.9546 0.723858 10.7307 0.5 10.4546 0.5C10.1784 0.5 9.95457 0.723858 9.95457 1H10.9546ZM9.95457 3.52248C9.95457 3.79862 10.1784 4.02248 10.4546 4.02248C10.7307 4.02248 10.9546 3.79862 10.9546 3.52248H9.95457ZM4 4.50118H11V3.50118H4V4.50118ZM13.5 6.9936V13.0076H14.5V6.9936H13.5ZM11 15.5H4V16.5H11V15.5ZM1.5 13.0076V6.9936H0.5V13.0076H1.5ZM4 15.5C2.61809 15.5 1.5 14.3829 1.5 13.0076H0.5C0.5 14.9376 2.0682 16.5 4 16.5V15.5ZM13.5 13.0076C13.5 14.3829 12.3819 15.5 11 15.5V16.5C12.9318 16.5 14.5 14.9376 14.5 13.0076H13.5ZM11 4.50118C12.3819 4.50118 13.5 5.61827 13.5 6.9936H14.5C14.5 5.0636 12.9318 3.50118 11 3.50118V4.50118ZM4 3.50118C2.0682 3.50118 0.5 5.0636 0.5 6.9936H1.5C1.5 5.61827 2.61809 4.50118 4 4.50118V3.50118ZM4.04543 1V3.52248H5.04543V1H4.04543ZM9.95457 1V3.52248H10.9546V1H9.95457ZM14.0024 7.06134L1.04451 6.999L1.03969 7.99899L13.9976 8.06133L14.0024 7.06134Z" fill="#787885"/>
              </svg>
              <span class="call-type">{{$t('dashboard.recordings.event')}}</span>
            </div>
          </div>
        </div>
        <div :class="['mobile-details', {'expand' : expandedRow && expandedRow.id === record.id}]">
          <div class="player">
            <audio :id="'audio' + record.id" @timeupdate="updateCurrentTime" @ended="audioPaused = true" :src="getMp3(record).uri" preload="auto" style="display: none;"></audio>
            <div class="slider-container" v-if="audio">
              <span class="audio-time">{{ getCurrentTime() }}</span>
              <input type="range" min="0" :max="Math.floor(audio.duration)" class="slider" v-model="audio.currentTime" @mousemove="updateProgress()">
              <span class="audio-time">{{ paddZero(Math.floor(getMp3(record).duration / 60)) + ":" + paddZero(Math.floor(getMp3(record).duration % 60)) }}</span>
              <img src="@/assets/images/audio-player/volume.svg" alt="Volume" id="volume" @click="showVolume = !showVolume">
              <input type="range" min="0" max="1" step="0.1" v-model="audio.volume" v-if="showVolume">

            </div>
            <div class="audio-controls" v-if="audio">
              <a href="#" class="audio-speed" @click.prevent="setAudioPlaybackRate()">{{audio.playbackRate}} <span class="times">X</span></a>
              <div class="play-controls">
                <img src="@/assets/images/audio-player/rewind-back.svg" @click="audio.currentTime -= 15" alt="RW">
                <img src="@/assets/images/audio-player/play.svg" class="play-pause" @click="audio.play(); audioPaused = false" alt="Play" v-if="audioPaused">
                <img src="@/assets/images/audio-player/pause.svg" class="play-pause" @click="audio.pause(); audioPaused = true" alt="Pause" v-else>
                <img src="@/assets/images/audio-player/fast-forward.svg" @click="audio.currentTime += 15" alt="FF">
              </div>
              <img src="@/assets/images/audio-player/delete.svg" alt="Delete" @click="highlightedRecordsIds.push(record.id); showDeleteModal = true">
            </div>
          </div>
          <div class="details-actions">
            <div class="tabs">
              <button class="tab transcribe" v-if="!record.transcription && getReceipts().transcriptions && getReceipts().transcriptions.is_active" @click="doTranscribeRecording(record.id)">{{ transcriptionInProgress === record.id ? $t('dashboard.recordings.transcription_in_progress') :  $t('dashboard.recordings.transcribe')}}</button>
              <button class="tab view-transcription" v-if="record.transcription && record.transcription.status === 'completed'" @click="showTranscription = true">{{$t('dashboard.recordings.view_transcription')}}</button>
              <button class="tab view-transcription" v-if="record.transcription && record.transcription.status === ('pending' || 'started' )">{{$t('dashboard.recordings.transcription_in_progress')}}</button>
              <button class="tab transcribe" v-if="record.transcription && record.transcription.status === ('failed' || 'error' || 'unknown' )">{{$t('dashboard.recordings.transcription_error')}}</button>
            </div>
          </div>
        </div>
        <div class="record-actions">
          <div class="record-action">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="bookmark" @click="startAddToList(record)"  v-if="checkLists(record.categories)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15 16.7175V10V2.5H11.25H8.75H5V10V16.7175L8.53542 14.9314L9.435 14.4763L10 14.1906L10.565 14.4763L11.4646 14.9314L15 16.7175ZM3.75 10V2.5C3.75 2.16848 3.8817 1.85054 4.11612 1.61612C4.35054 1.3817 4.66848 1.25 5 1.25H8.75H11.25H15C15.3315 1.25 15.6495 1.3817 15.8839 1.61612C16.1183 1.85054 16.25 2.16848 16.25 2.5V10V18.75L10 15.5912L3.75 18.75V10Z" fill="#333333"/>
              <path d="M15.2019 2.13672H4.78828L4.57861 17.4779L10.065 14.6473L15.5863 17.2332L15.2019 2.13672Z" fill="#333333" stroke="#333333"/>
            </svg>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="bookmark" @click="startAddToList(record)" v-else>
              <path d="M15 9.9H14.9V10V16.5573L10.6101 14.387L10.6101 14.387L10.0451 14.1014L9.99995 14.0785L9.9548 14.1014L9.39241 14.3864L9.3923 14.3864L5.1 16.5549V2.6H11.25H11.35V2.5V1.25V1.15H11.25H5C4.64196 1.15 4.29858 1.29223 4.04541 1.54541C3.79223 1.79858 3.65 2.14196 3.65 2.5V18.75V18.9126L3.79511 18.8392L10 15.7033L16.2049 18.8392L16.35 18.9126V18.75V10V9.9H16.25H15Z" fill="#333333" stroke="#333333" stroke-width="0.2"/>
              <path d="M12.5 5.1H14.9V7.5V7.6H15H16.25H16.35V7.5V5.1H18.75H18.85V5V3.75V3.65H18.75H16.35V1.25V1.15H16.25H15H14.9V1.25V3.65H12.5H12.4V3.75V5V5.1H12.5Z" fill="#333333" stroke="#333333" stroke-width="0.2"/>
            </svg>
          </div>
          <div class="record-action">
            <a :href="getMp3(record).uri" class="details-anchor" download>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="download">
                <path d="M13 28H27V26H13V28ZM27 17H23V11H17V17H13L20 24L27 17Z" fill="#333333"/>
              </svg>
            </a>
          </div>
          <div class="record-action" @click.stop="activeSubmenuID = record.id">
            <a class="details-anchor">
              <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="share">
                <path d="M9.33333 1L12.6667 4.33333M9.33333 1V11V1ZM9.33333 1L6 4.33333L9.33333 1Z" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 12.6667L1.5175 14.7376C1.60763 15.0981 1.81569 15.4182 2.10861 15.647C2.40153 15.8757 2.76251 16 3.13417 16.0001H15.5325C15.9042 16 16.2651 15.8757 16.5581 15.647C16.851 15.4182 17.059 15.0981 17.1492 14.7376L17.6667 12.6667" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </a>
            <div class="submenu" v-if="activeSubmenuID && activeSubmenuID === record.id" v-click-outside="resetSID">
              <button @click.stop="shareRecording(record.id);sharingAction = 'mp3';activeSubmenuID = null">
                {{$t('dashboard.recordings.share')}} MP3 {{$t('dashboard.recordings.recording')}}
              </button>
              <button @click.stop="shareRecording(record.id);sharingAction = 'transcription'; activeSubmenuID = null">
                {{$t('dashboard.recordings.share')}} {{$t('dashboard.recordings.transcription')}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <table class="recordings">
      <thead>
        <tr>
          <td></td>
          <td></td>
          <td>{{$t('dashboard.recordings.label')}}</td>
          <td></td>
          <td>{{$t('dashboard.recordings.date')}}</td>
          <td>{{$t('dashboard.recordings.status')}}</td>
          <td>{{$t('dashboard.recordings.recording')}}</td>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody v-if="paginatedRecordings.length > 0">
        <template v-for="record in paginatedRecordings">
          <tr :key="record.id" :class="{'highlighted' : highlightedRecordsIds.includes(record.id)}">
            <td>
              <label class="checkmark-custom">
                <input type="checkbox" @change="highlightRecord(record.id)" :checked="highlightedRecordsIds.includes(record.id)">
                <span class="checkmark"></span>
              </label>
            </td>
            <td @click="expandRow($event, record)">
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" :class="['expand', {'rotate' : expandedRow && expandedRow.id === record.id}]">
                <path d="M10.2109 0.863281L11.5 2.15234L6 7.65234L0.5 2.15234L1.78906 0.863281L6 5.07422L10.2109 0.863281Z" fill="#666666"/>
              </svg>
            </td>
            <td @click="expandRow($event, record)">
              <span class="call-label">{{ record.user_label ? record.user_label : record.id  }}</span>
              <span class="call-number">{{ record.number }}</span>
            </td>
            <td>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="bookmark" @click="startAddToList(record)" v-if="checkLists(record.categories)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15 16.7175V10V2.5H11.25H8.75H5V10V16.7175L8.53542 14.9314L9.435 14.4763L10 14.1906L10.565 14.4763L11.4646 14.9314L15 16.7175ZM3.75 10V2.5C3.75 2.16848 3.8817 1.85054 4.11612 1.61612C4.35054 1.3817 4.66848 1.25 5 1.25H8.75H11.25H15C15.3315 1.25 15.6495 1.3817 15.8839 1.61612C16.1183 1.85054 16.25 2.16848 16.25 2.5V10V18.75L10 15.5912L3.75 18.75V10Z" fill="#333333"/>
                <path d="M15.2019 2.13672H4.78828L4.57861 17.4779L10.065 14.6473L15.5863 17.2332L15.2019 2.13672Z" fill="#333333" stroke="#333333"/>
              </svg>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="bookmark" @click="startAddToList(record)" v-else>
                <path d="M15 9.9H14.9V10V16.5573L10.6101 14.387L10.6101 14.387L10.0451 14.1014L9.99995 14.0785L9.9548 14.1014L9.39241 14.3864L9.3923 14.3864L5.1 16.5549V2.6H11.25H11.35V2.5V1.25V1.15H11.25H5C4.64196 1.15 4.29858 1.29223 4.04541 1.54541C3.79223 1.79858 3.65 2.14196 3.65 2.5V18.75V18.9126L3.79511 18.8392L10 15.7033L16.2049 18.8392L16.35 18.9126V18.75V10V9.9H16.25H15Z" fill="#333333" stroke="#333333" stroke-width="0.2"/>
                <path d="M12.5 5.1H14.9V7.5V7.6H15H16.25H16.35V7.5V5.1H18.75H18.85V5V3.75V3.65H18.75H16.35V1.25V1.15H16.25H15H14.9V1.25V3.65H12.5H12.4V3.75V5V5.1H12.5Z" fill="#333333" stroke="#333333" stroke-width="0.2"/>
              </svg>
            </td>
            <td><span class="call-date">{{getDate(record.created)}}</span><span class="call-time">{{getTime(record.created)}}</span></td>
            <td>
              <span class="badge transcribed" v-if="record.transcription && record.transcription.status === 'completed'">{{$t('dashboard.header.transcribed')}}</span>
              <span class="badge" v-else>{{$t('dashboard.recordings.not')}} {{$t('dashboard.header.transcribed')}}</span>
            </td>
            <td>
              <div class="flex" v-if="!record.meeting.id">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.11003 10.8643C4.88673 11.6397 5.70658 12.3398 6.56958 12.9645C7.43258 13.5891 8.29558 14.0845 9.15858 14.4507C10.0216 14.8169 10.8387 15 11.61 15C12.1332 15 12.6214 14.9058 13.0744 14.7173C13.5275 14.5288 13.9374 14.2326 14.3042 13.8288C14.52 13.5918 14.6899 13.336 14.8139 13.0614C14.938 12.7921 15 12.5202 15 12.2456C15 12.0409 14.9595 11.8471 14.8786 11.664C14.7977 11.4755 14.6602 11.3139 14.466 11.1793L11.9822 9.41842C11.7934 9.28918 11.6181 9.19224 11.4563 9.12762C11.2945 9.063 11.1408 9.03069 10.9951 9.03069C10.8118 9.03069 10.6338 9.08185 10.4612 9.18417C10.2886 9.2811 10.1106 9.42111 9.92718 9.6042L9.35275 10.1696C9.27185 10.2558 9.16936 10.2989 9.04531 10.2989C8.98058 10.2989 8.91855 10.2881 8.85922 10.2666C8.80529 10.245 8.75944 10.2262 8.72168 10.21C8.46818 10.0754 8.14455 9.84383 7.75081 9.51535C7.35707 9.18148 6.96063 8.81529 6.56149 8.4168C6.16775 8.01831 5.80367 7.62251 5.46926 7.2294C5.13484 6.8363 4.90291 6.51589 4.77346 6.26817C4.75189 6.22509 4.73031 6.17663 4.70874 6.12278C4.69256 6.06354 4.68447 6.00431 4.68447 5.94507C4.68447 5.8266 4.72492 5.72698 4.80583 5.6462L5.38026 5.05654C5.55825 4.87345 5.69849 4.69575 5.80097 4.52342C5.90345 4.3511 5.95469 4.1734 5.95469 3.99031C5.95469 3.84491 5.91963 3.69144 5.84951 3.52989C5.78479 3.36834 5.68501 3.19332 5.55016 3.00485L3.81068 0.557351C3.67044 0.36349 3.50324 0.223479 3.30906 0.137318C3.11489 0.0457727 2.90992 0 2.69417 0C2.13862 0 1.61812 0.234249 1.13269 0.702746C0.738943 1.0797 0.450378 1.49704 0.26699 1.95477C0.0889968 2.40711 0 2.89176 0 3.40872C0 4.18417 0.18069 5 0.542071 5.85622C0.903452 6.70705 1.39428 7.56058 2.01456 8.4168C2.63484 9.27302 3.33333 10.0889 4.11003 10.8643Z" fill="#787885"/>
                </svg>
                <span class="call-type">{{$t('dashboard.recordings.call')}}</span>
              </div>
              <div class="flex" v-if="record.meeting.id">
                <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.04543 1C5.04543 0.723858 4.82157 0.5 4.54543 0.5C4.26929 0.5 4.04543 0.723858 4.04543 1H5.04543ZM4.04543 3.52248C4.04543 3.79862 4.26929 4.02248 4.54543 4.02248C4.82157 4.02248 5.04543 3.79862 5.04543 3.52248H4.04543ZM10.9546 1C10.9546 0.723858 10.7307 0.5 10.4546 0.5C10.1784 0.5 9.95457 0.723858 9.95457 1H10.9546ZM9.95457 3.52248C9.95457 3.79862 10.1784 4.02248 10.4546 4.02248C10.7307 4.02248 10.9546 3.79862 10.9546 3.52248H9.95457ZM4 4.50118H11V3.50118H4V4.50118ZM13.5 6.9936V13.0076H14.5V6.9936H13.5ZM11 15.5H4V16.5H11V15.5ZM1.5 13.0076V6.9936H0.5V13.0076H1.5ZM4 15.5C2.61809 15.5 1.5 14.3829 1.5 13.0076H0.5C0.5 14.9376 2.0682 16.5 4 16.5V15.5ZM13.5 13.0076C13.5 14.3829 12.3819 15.5 11 15.5V16.5C12.9318 16.5 14.5 14.9376 14.5 13.0076H13.5ZM11 4.50118C12.3819 4.50118 13.5 5.61827 13.5 6.9936H14.5C14.5 5.0636 12.9318 3.50118 11 3.50118V4.50118ZM4 3.50118C2.0682 3.50118 0.5 5.0636 0.5 6.9936H1.5C1.5 5.61827 2.61809 4.50118 4 4.50118V3.50118ZM4.04543 1V3.52248H5.04543V1H4.04543ZM9.95457 1V3.52248H10.9546V1H9.95457ZM14.0024 7.06134L1.04451 6.999L1.03969 7.99899L13.9976 8.06133L14.0024 7.06134Z" fill="#787885"/>
                </svg>
                <span class="call-type">{{$t('dashboard.recordings.event')}}</span>
              </div>
            </td>
            <td class="hover">
              <a :href="getMp3(record).uri" class="details-anchor" download="">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="download">
                  <path d="M13 28H27V26H13V28ZM27 17H23V11H17V17H13L20 24L27 17Z" fill="#333333"/>
                </svg>
              </a>
            </td>
            <td class="hover" @click.stop="activeSubmenuID = record.id">
              <a class="details-anchor">
                <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="share">
                  <path d="M9.33333 1L12.6667 4.33333M9.33333 1V11V1ZM9.33333 1L6 4.33333L9.33333 1Z" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M1 12.6667L1.5175 14.7376C1.60763 15.0981 1.81569 15.4182 2.10861 15.647C2.40153 15.8757 2.76251 16 3.13417 16.0001H15.5325C15.9042 16 16.2651 15.8757 16.5581 15.647C16.851 15.4182 17.059 15.0981 17.1492 14.7376L17.6667 12.6667" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
              <div class="submenu" v-if="activeSubmenuID && activeSubmenuID === record.id" v-click-outside="resetSID">
                <button @click.stop="shareRecording(record.id);sharingAction = 'mp3';activeSubmenuID = null">
                  {{$t('dashboard.recordings.share')}} MP3 {{$t('dashboard.recordings.recording')}}
                </button>
                <button @click.stop="shareRecording(record.id);sharingAction = 'transcription';activeSubmenuID = null">
                  {{$t('dashboard.recordings.share')}} {{$t('dashboard.recordings.transcription')}}
                </button>
              </div>
            </td>
          </tr>
          <tr class="no-border" :key="record.id + 'details'">
            <td colspan="12">
              <div class="details">
                <div class="player">
                  <audio :id="'audio' + record.id" @timeupdate="updateCurrentTime" @ended="audioPaused = true" :src="getMp3(record).uri" preload="auto" style="display: none;"></audio>
                  <div class="slider-container" v-if="audio">
                    <span class="audio-time">{{ getCurrentTime() }}</span>
                    <input type="range" min="0" :max="Math.floor(audio.duration)" class="slider" v-model="audio.currentTime" @mousemove="updateProgress()">
                    <span class="audio-time">{{ paddZero(Math.floor(audio.duration / 60)) + ":" + paddZero(Math.floor(audio.duration % 60)) }}</span>
                    <img src="@/assets/images/audio-player/volume.svg" alt="Volume" id="volume" @click="showVolume = !showVolume">
                    <input type="range" min="0" max="1" step="0.1" v-model="audio.volume" v-if="showVolume">
                  </div>
                  <div class="audio-controls" v-if="audio">
                    <a href="#" class="audio-speed" @click.prevent="setAudioPlaybackRate()">{{audio.playbackRate}} <span class="times">X</span></a>
                    <div class="play-controls">
                      <img src="@/assets/images/audio-player/rewind-back.svg" @click="audio.currentTime -= 15" alt="RW">
                      <img src="@/assets/images/audio-player/play.svg" class="play-pause" @click="audio.play(); audioPaused = false" alt="Play" v-if="audioPaused">
                      <img src="@/assets/images/audio-player/pause.svg" class="play-pause" @click="audio.pause(); audioPaused = true" alt="Pause" v-else>
                      <img src="@/assets/images/audio-player/fast-forward.svg" @click="audio.currentTime += 15" alt="FF">
                    </div>
                  </div>
                </div>
                <div class="details-actions">
                  <div class="tabs">
                    <button class="tab transcribe" v-if="!record.transcription && getReceipts().transcriptions && getReceipts().transcriptions.is_active" @click="doTranscribeRecording(record.id)">{{ transcriptionInProgress === record.id ? $t('dashboard.recordings.transcription_in_progress') :  $t('dashboard.recordings.transcribe')}}</button>
                    <button class="tab view-transcription" v-if="record.transcription && record.transcription.status === 'completed'" @click="showTranscription = true">{{$t('dashboard.recordings.view_transcription')}}</button>
                    <button class="tab view-transcription" v-if="record.transcription && record.transcription.status === ('pending' || 'started' )">{{$t('dashboard.recordings.transcription_in_progress')}}</button>
                    <button class="tab transcribe" v-if="record.transcription && record.transcription.status === ('failed' || 'error' || 'unknown' )" @click="doTranscribeRecording(record.id)">{{$t('dashboard.recordings.transcription_error')}}</button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
      <tbody v-else>
        <tr class="no-data">
          <td colspan="12">{{$t('dashboard.recordings.no_recordings_found')}}</td>
        </tr>
      </tbody>
    </table>

    <div class="pagination" v-if="pagination > 0">
      <span @click="currentPage = currentPage - 1; hideColumns()" v-if="currentPage > 1">&lt;</span>

      <div v-if="pagination < 11">
        <span v-for="(page, index) in pagination" :key="'page-' + index" :class="{'active' : currentPage === page}" @click="currentPage = page; hideColumns()">{{ page }}</span>
      </div>
      <div v-else>
        <span v-if="currentPage !== 1" @click="currentPage = 1; hideColumns()">1</span>
        <span v-if="currentPage > 2">...</span>
        <span @click="currentPage = currentPage - 1; hideColumns()" v-if="(currentPage -1) > 2">{{ currentPage - 1 }}</span>
        <span class="active">{{ currentPage }}</span>
        <span @click="currentPage = currentPage + 1; hideColumns()" v-if="(currentPage + 1) < pagination - 1">{{ currentPage + 1 }}</span>
        <span v-if="(currentPage + 1) < pagination">...</span>
        <span @click="currentPage = pagination; hideColumns()" v-if="currentPage !== pagination">{{ pagination }}</span>
      </div>

      <span @click="currentPage = currentPage + 1; hideColumns()" v-if="currentPage < pagination">&gt;</span>
    </div>

    <Modal v-if="getShowQRCodes()" :className="'qr-modal'" @confirm="setShowQRCodes(false); setHighlightQR(false)">
      <template v-slot:header>
        <div>
          <p class="qr-header">Scan the QR code and download TapeACall</p>
          <div class="flex-qr">
            <div class="qr-wrapper">
              <img src="@/assets/images/download-images/tapeacall-ios-qr-code.png" alt="TapeACall iOS">
              <span>iOS</span>
            </div>
            <div class="qr-wrapper">
              <img src="@/assets/images/download-images/tapeacall-android-qr-code.png" alt="TapeACall Android">
              <span>Android</span>
            </div>
          </div>
        </div>
      </template>
    </Modal>

    <Modal v-if="sharingID" :className="'align-left'">
      <template v-slot:header>
        <div class="create-new-list">
          <h1>{{$t('dashboard.recordings.share')}} {{ sharingAction === 'transcription' ? $t('dashboard.recordings.transcription') : 'MP3'}}</h1>
          <hr>
          <input type="text" class="share-input" v-model="shareEmail" :placeholder="$t('dashboard.recordings.add_email_name')">
          <p class="error" v-if="errorSharing">{{ $t('dashboard.recordings.input_name') }}</p>
          <a class="copy-link" href="#" @click.prevent="copyLink()"><img src="@/assets/images/link.svg" :alt="$t('dashboard.recordings.copy_link')">{{ $t('dashboard.recordings.copy_link') }}</a>
          <p v-html="copyLinkHtml" id="copyLinkData" v-show="false"></p>
          <p class="error" v-if="errorCopying">{{ $t('dashboard.recordings.cannot_copy_link') }}</p>
          <div class="actions">
            <p @click="sharingID = null; linkCopied = false">{{ $t('dashboard.sidebar.cancel') }}</p>
            <button class="create" @click="share()">{{ $t('dashboard.recordings.share') }}</button>
          </div>
        </div>
        <div class="toast" v-if="linkCopied">
          <div>
            <p>{{ $t('dashboard.recordings.link_copied') }}</p>
            <p v-html="copyLinkHtml"></p>
          </div>
          <span @click="linkCopied = false; sharingID = false">{{ $t('dashboard.recordings.close') }}</span>
        </div>
      </template>
    </Modal>

    <Modal v-if="addToList" :className="'align-left'">
      <template v-slot:header>
        <div class="toast up" v-if="showRecordAdded">
          <img src="@/assets/images/check-ok.svg" alt="OK">
          <div>
            <p><b>{{ currentRecord.user_label ? currentRecord.user_label : currentRecord.id }}</b> {{ $t('dashboard.recordings.was_added_to') }}</p>
          </div>
          <div class="toast-actions">
            <span class="undo" @click="undoAddingToList()">Undo</span>
            <span class="cancel" @click="resetAddingToListData()">{{ $t('dashboard.recordings.close') }}</span>
          </div>
        </div>
        <div class="create-new-list">
          <h1>{{$t('dashboard.recordings.add_to_list')}}</h1>
          <hr>
          <p>{{ $t('dashboard.recordings.select_a_list') }}</p>
          <div class="choose-list">
            <div v-for="list in lists" :key="list.id">
              <span>{{ list.name }}</span>
              <label class="checkmark-custom">
                <input type="checkbox" @change="highlightList(list.id)" :checked="highlightedLists.includes(list.id)">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="actions">
            <p @click="resetAddingToListData()" v-if="!showRecordAdded">{{ $t('dashboard.sidebar.cancel') }}</p>
            <p @click="resetAddingToListData()" v-else>{{ $t('dashboard.recordings.close') }}</p>
            <button class="create" @click="addRecordToList()" v-if="highlightedLists.length > 0 && !showRecordAdded">{{ $t('dashboard.recordings.add') }}</button>
          </div>
        </div>
      </template>
    </Modal>

    <Modal v-if="showDeleteModal" :className="'align-left'">
      <template v-slot:header>
        <div class="toast up error" v-if="recordsDeleted">
          <img src="@/assets/images/check-ok.svg" alt="OK">
          <div>
            <p>{{ $t('dashboard.recordings.deleted') }}</p>
          </div>
          <div class="toast-actions">
            <span class="cancel" @click="recordsDeleted = false; showDeleteModal = false">{{ $t('dashboard.recordings.close') }}</span>
          </div>
        </div>
        <div class="create-new-list">
          <p class="delete-confirm">{{ $t('dashboard.recordings.are_you_sure_to_delete') }}</p>
          <div class="actions">
            <p @click="recordsDeleted = false; showDeleteModal = false" v-if="recordsDeleted">{{ $t('dashboard.recordings.close') }}</p>
            <p @click="showDeleteModal = false" v-else>{{ $t('dashboard.sidebar.cancel') }}</p>
            <button class="create error" @click="deleteRecordings()" v-if="!recordsDeleted">{{ $t('dashboard.recordings.delete') }}</button>
          </div>
        </div>
      </template>
    </Modal>

    <Modal v-if="showTranscription" :className="'align-left transcription'" @close="showTranscription = false">
      <template v-slot:header>
        <div class="transcription">
          <div class="transcription-header">
            <h1>{{ expandedRow.user_label ? expandedRow.user_label : expandedRow.id}}</h1>
            <div class="actions">
              <span class="edit-transcription-info" @click.stop="editSaveTranscription()" v-if="saving">{{ $t('dashboard.recordings.saving') }}...</span>
              <span class="edit-transcription-info" @click.stop="editSaveTranscription()" v-else-if="editingTranscription">{{ $t('dashboard.recordings.save') }}</span>
              <span class="edit-transcription-info" @click.stop="editSaveTranscription()" v-else>{{ $t('dashboard.recordings.edit') }}</span>
              <a href="#" @click.prevent="downloadTxt(expandedRow)">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="download">
                  <path d="M13 28H27V26H13V28ZM27 17H23V11H17V17H13L20 24L27 17Z" fill="#333333"/>
                </svg>
              </a>
              <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="share" @click.stop="activeSubmenuID = expandedRow.id;showTranscription = false">
                <path d="M9.33333 1L12.6667 4.33333M9.33333 1V11V1ZM9.33333 1L6 4.33333L9.33333 1Z" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 12.6667L1.5175 14.7376C1.60763 15.0981 1.81569 15.4182 2.10861 15.647C2.40153 15.8757 2.76251 16 3.13417 16.0001H15.5325C15.9042 16 16.2651 15.8757 16.5581 15.647C16.851 15.4182 17.059 15.0981 17.1492 14.7376L17.6667 12.6667" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
          
          <div class="conversation">
            <div class="bubble" v-for="(transcription, index) in expandedRow.transcription.data.transcription" :key="expandedRow.id + index" :class="{'right' : index !== 0 && transcription.speaker_label !== expandedRow.transcription.data.transcription[0].speaker_label}">
              <div class="bubble-header">
                <span class="name">{{ transcription.speaker_label }}</span>
                <span class="time">{{ transcription.end_time }}</span>
                <template>
                  <p v-if="!editingTranscription">{{ transcription.transcript }}</p>
                  <textarea type="text" v-model="expandedRow.transcription.data.transcription[index].transcript" @keyup="setAutoSaveTimeout()" @click.stop="" v-if="editingTranscription"></textarea>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import $ from 'jquery'
import Vue from 'vue'
import multiDownload from 'multi-download'
import {ALL_CALLS, ALL_LISTS} from '../assets/constants'
import Modal from '@/components/ModalComponent'

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el == event.target || el.contains(event.target))) {
        if(vnode.context['activeSubmenuID']) {
          vnode.context[binding.expression](event)
        }
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

export default {
  name: 'Recordings',
  props: {
    callFilter: String,
    listFilter: Object
  },
  components: {
    Modal
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      searchFilter: '',
      expandedRow: null,
      activeSubmenuID: null,
      highlightedRecordsIds:[],
      highlightedLists: [],
      showTranscription: false,
      sharingID: null,
      shareEmail: '',
      errorSharing: false,
      errorCopying: false,
      addToList: false,
      currentRecord: null,
      showRecordAdded: false,
      showDeleteModal: false,
      recordsDeleted: false,
      linkCopied: false,
      audio: null,
      showVolume: false,
      currentTime: 0,
      audioPaused: true,
      searchTimeout: null,
      transcribingText: '',
      copyLinkHtml: '',
      sharingAction: 'mp3',
      transcriptionInProgress: null,
      editingTranscription: false,
      saving: false,
      editTranscriptionTimeout: null,
      expandBug: false
    }
  },
  computed: {
    filteredRecordings() {
      if(this.callFilter === ALL_CALLS && this.listFilter.name === ALL_LISTS) {
        return this.getFilteredRecordings().filter((entry) => entry.user_label ? entry.user_label.toLowerCase().indexOf(this.searchFilter.toLowerCase()) !== -1 : entry.id.toLowerCase().indexOf(this.searchFilter.toLowerCase()) !== -1 )
      } else if(this.callFilter !== ALL_CALLS && this.listFilter.name === 'dashboard.sidebar.all_lists' ) {
        if(this.callFilter === 'call') {
          return this.getFilteredRecordings().filter((entry) => !entry.meeting.id && (entry.user_label ? entry.user_label.toLowerCase().indexOf(this.searchFilter.toLowerCase()) !== -1 : entry.id.toLowerCase().indexOf(this.searchFilter.toLowerCase()) !== -1))
        } else {
          return this.getFilteredRecordings().filter((entry) => entry.meeting.id && (entry.user_label ? entry.user_label.toLowerCase().indexOf(this.searchFilter.toLowerCase()) !== -1 : entry.id.toLowerCase().indexOf(this.searchFilter.toLowerCase()) !== -1))
        }
      } else if(this.callFilter === ALL_CALLS && this.listFilter.name !== ALL_LISTS) {
        return this.getFilteredRecordings().filter((entry) => {
          let belongs = false
          entry.categories.forEach((cat) => {
            if(cat.id === this.listFilter.id) {
              belongs = true
            }
          })
          if(belongs && (entry.user_label ? entry.user_label.toLowerCase().indexOf(this.searchFilter.toLowerCase()) !== -1 : entry.id.toLowerCase().indexOf(this.searchFilter.toLowerCase()) !== -1)) return entry
        })
      } else {
        if(this.callFilter === 'call') {
          return this.getFilteredRecordings().filter((entry) => {
            let belongs = false
            entry.categories.forEach((cat) => {
              if(cat.id === this.listFilter.id) {
                belongs = true
              }
            })
            if(belongs && !entry.meeting.id && (entry.user_label ? entry.user_label.toLowerCase().indexOf(this.searchFilter.toLowerCase()) !== -1 : entry.id.toLowerCase().indexOf(this.searchFilter.toLowerCase()) !== -1)) return entry
          })
        } else {
          return this.getFilteredRecordings().filter((entry) => {
            let belongs = false
            entry.categories.forEach((cat) => {
              if(cat.id === this.listFilter.id) {
                belongs = true
              }
            })
            if(belongs && entry.meeting.id && (entry.user_label ? entry.user_label.toLowerCase().indexOf(this.searchFilter.toLowerCase()) !== -1 : entry.id.toLowerCase().indexOf(this.searchFilter.toLowerCase()) !== -1)) return entry
          })
        }
      }
    },
    paginatedRecordings() {
      return this.filteredRecordings.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
    pagination() {
      let pages = this.filteredRecordings.length / this.pageSize
      if(this.isInt(pages)) {
        return pages
      }
      return Math.floor(pages) + 1
    },
    lists() {
      return this.getLists().filter((entry) => entry.name !== 'dashboard.sidebar.all_lists')
    }
  },
  watch: {
    filteredRecordings: function() {
      if(!this.expandBug) {
        this.hideColumns()
        this.expandedRow = null
        this.expandBug = true
      }
    },
    searchFilter: function() {
      this.hideColumns()
      this.currentPage = 1
      this.expandedRow = null
    },
    callFilter: function() {
      this.hideColumns()
      this.currentPage = 1
      this.expandedRow = null
    },
    listFilter: function() {
      this.hideColumns()
      this.currentPage = 1
      this.expandedRow = null
    }
  },
  beforeMount() {
    this.fetchRecordings()
  },
  mounted() {
    this.hideColumns()
  },
  methods: {
    ...mapActions([
      'deleteRecordingsApi',
      'fetchSharingUrl',
      'fetchRecordings',
      'updateLists',
      'addCategoriesToRecording',
      'transcribeRecording',
      'editTranscription'
    ]),
    ...mapGetters([
      'getRecordings',
      'getFilteredRecordings',
      'getLists',
      'getMobileShowFilterLists',
      'getSearchTerm',
      'getShowQRCodes',
      'getReceipts'
    ]),
    ...mapMutations([
      'setRecordings',
      'setMobileShowFilterLists',
      'setShowQRCodes',
      'setHighlightQR',
      'setSearchTerm'
    ]),
    hideColumns() {
      $(function() {
        $('td[colspan=12]').find('.details').hide()
      })
    },
    getMp3(record) {
      return record.recordings.filter((entry) => entry.filetype === 'mp3')[0]
    },
    downloadTxt(record) {
      let link = document.createElement('a')
      let blobArray = []
      record.transcription.data.transcription.forEach((object) => {
        let entry = object.speaker_label + ' at ' + object.start_time + '\r\n' + object.transcript + '\r\n' + '\r\n'
        blobArray.push(entry)
      })
      const txt = new Blob(blobArray, { type: 'text/plain' })
      const url = URL.createObjectURL(txt)
      link.href = url
      link.target = '_blank'
      link.download = 'transcription-' + record.id
      link.innerHTML = link.download
      link.click()
      link.remove()
    },
    updateCurrentTime() {
      if(!this.audio) return
      this.currentTime = this.audio.currentTime
      this.updateProgress()
    },
    getCurrentTime() {
      let time = this.currentTime
      return time < 60 ? "00:" + this.paddZero(Math.floor(time)) : this.paddZero(Math.floor(time / 60)) + ":" + this.paddZero(Math.floor(time % 60))
    },
    updateProgress() {
      let slider = $('.slider')
      let val = (slider.val() - slider.attr('min')) / (slider.attr('max') - slider.attr('min'))

      slider.css('background-image',
        '-webkit-gradient(linear, left top, right top, '
        + 'color-stop(' + val + ', #007AFF), '
        + 'color-stop(' + val + ', rgba(142, 142, 147, 0.2))'
        + ')'
      )
    },
    paddZero(i) {
       if (i < 10) {
         i = "0" + i
       }
       return i
    },
    setAudio(id) {
      if(this.audio) {
        this.audio.currentTime = 0
        this.audio.pause()
        this.audioPaused = true
      }

      if(id === null) {
        this.audio = null
        return
      }

      let audioId = 'audio' + id
      this.audio = document.getElementById(audioId)
    },
    setAudioPlaybackRate() {
      if(this.audio.playbackRate === 2) {
        this.audio.playbackRate = 1
      } else {
        this.audio.playbackRate = 2
      }
    },
    highlightRecord(id) {
      if(this.highlightedRecordsIds.includes(id)) {
        this.highlightedRecordsIds = this.highlightedRecordsIds.filter((existingId) => existingId !== id)
      } else {
        this.highlightedRecordsIds.push(id)
      }
    },
    highlightList(id) {
      if(this.highlightedLists.includes(id)) {
        this.highlightedLists = this.highlightedLists.filter((existingId) => existingId !== id)
      } else {
        this.highlightedLists.push(id)
      }
    },
    checkLists(categories) {
      let belongsToList = false
      this.lists.forEach((entry) => {
        categories.forEach((cat) => {
          if(cat.id === entry.id) {
            belongsToList = true
          }
        })
      })

      return belongsToList
    },
    startAddToList(record) {
      this.currentRecord = record
      this.addToList = true

      record.categories.forEach((category) => {
        this.highlightedLists.push(category.id)
      })
    },
    addRecordToList() {
      if(this.showRecordAdded) return

      let data = {
        recordingID: this.currentRecord.id,
        lists: this.highlightedLists
      }

      this.addCategoriesToRecording(data).then(() => {
        this.showRecordAdded = true
      }).catch((error) => {
        console.error('add error', error)
      })
    },
    undoAddingToList() {
      let data = {
        recordingID: this.currentRecord.id,
        lists: []
      }

      this.addCategoriesToRecording(data).then(() => {
        this.resetAddingToListData()
      }).catch((error) => {
        console.error('undo error', error)
      })
    },
    resetAddingToListData(){
      this.showRecordAdded = false
      this.currentRecord = null
      this.addToList = false
      this.highlightedLists = []
    },
    expandRow(event, record){
      event.stopPropagation()
      let $target = $(event.target)
      $('.details').slideUp()

      if(this.expandedRow !== null && this.expandedRow.id === record.id) {
        this.expandedRow = null
        this.setAudio(null)
      } else {
        this.expandedRow = record
        $target.closest('tr').next().find('.details').slideToggle()
        this.setAudio(record.id)
      }
    },
    getDate(date) {
      return new Date(date).toISOString().substring(0, 10);
    },
    getTime(date) {
      return new Date(date).getHours() + ':' + this.addZero(new Date(date).getMinutes())
    },
    addZero(n) {
      return (n < 10 ? '0' : '') + n
    },
    isInt(n) {
       return n % 1 === 0
    },
    copyLink() {
      if(this.copyLinkHtml.length === 0) {
        return
      } else {
        var $temp = $('<input>')
        $('body').append($temp)
        $temp.val($('#copyLinkData').text()).select()
        document.execCommand('copy')
        $temp.remove()
        this.linkCopied = true
      }
    },
    doTranscribeRecording(id) {
      if(this.transcriptionInProgress !== id) {
        this.transcribeRecording(id).then((res) => {
          this.transcriptionInProgress = id
          if(res.data.status === 'completed') {
            this.expandedRow['transcription'] = res.data
            this.transcriptionInProgress = null
          }
        }).catch((error) => {
          console.error('transcribe error', error)
        })
      }
    },
    shareRecording(id) {
      this.errorCopying = false
      this.sharingID = id
      this.fetchSharingUrl(id).then((response) => {
        this.copyLinkHtml = response.data.url
      }).catch((error) => {
        this.errorCopying = true
        console.error('sharing error', error)
      })
    },
    share() {
      this.errorSharing = false
      if(this.shareEmail.length < 4 || !this.validEmail(this.shareEmail)) {
        this.errorSharing = true
        return
      }

      if(this.sharingAction === 'mp3') {
        window.location.href = 'mailto:' + this.shareEmail + '?subject=TapeACall Recording&body=Public URL: ' + this.copyLinkHtml;
      } else {
        let record = this.filteredRecordings.filter((rec) => rec.id === this.sharingID)
        let transcript = 'Public URL: ' + this.copyLinkHtml + '%0D%0A%0D%0A' + 'Transcript:' + '%0D%0A'
        if(record.length > 0 && record[0].transcription && record[0].transcription.data) {
          record[0].transcription.data.transcription.forEach((bubble) => {
            transcript += bubble.speaker_label + ' at ' + bubble.start_time + '%0D%0A' + bubble.transcript + '%0D%0A%0D%0A'
          })
        }

        window.location.href = 'mailto:' + this.shareEmail + '?subject=TapeACall Recording Transcript&body=' + transcript;
      }
    },
    validEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email)
    },
    resetSID() {
      if(this.activeSubmenuID) {
        this.activeSubmenuID = null
      }
    },
    deleteRecordings() {
      this.highlightedRecordsIds.forEach((id) =>{
        this.deleteRecordingsApi(id).then(() => {
          this.fetchRecordings()
          this.hideColumns()
        }).catch((error) => {
          console.error('Error deleting record', error)
        })
      })
      this.recordsDeleted = true
      this.highlightedRecordsIds = []
    },
    downloadMultiple() {
      let downloadUrls = []
      this.highlightedRecordsIds.forEach((id) =>{
        let recording = this.getRecordings().find(record => record.id === id)
        if(recording !== undefined) {
          downloadUrls.push(this.getMp3(recording).uri)
        }
      })
      multiDownload(downloadUrls)
    },
    setAutoSaveTimeout() {
      if(this.editTranscriptionTimeout) {
        clearTimeout(this.editTranscriptionTimeout)
      }

      this.editTranscriptionTimeout = setTimeout(() => {
        this.saveEditedTranscription()
      }, 10000)
    },
    saveEditedTranscription() {
      this.saving = true
      this.editTranscription(this.expandedRow.transcription.data).then(() => {
        this.saving = false
      }).catch((error) => {
        console.error('Error editing transcription', error)
      })
    },
    editSaveTranscription() {
      if(this.editingTranscription) {
        this.editingTranscription = false

        if(this.editTranscriptionTimeout) {
          clearTimeout(this.editTranscriptionTimeout)
        }

        this.saveEditedTranscription()
        return
      }
      this.editingTranscription = true
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/partials/_recordings-list";
</style>
