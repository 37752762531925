<template>
  <div class="content-wrapper">
    <Sidebar @callFilter="setActiveCallFilter"  @listFilter="setActiveListFilter"/>
    <RecordingsList :callFilter="activeCallFilter" :listFilter="activeListFilter"/>
  </div>
</template>

<script>
import Sidebar from '@/components/RecordingsSidebar'
import RecordingsList from '@/components/RecordingsList'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Recordings',
  components: {
    RecordingsList,
    Sidebar
  },
  data() {
    return {
      activeCallFilter: 'all_calls',
      activeListFilter: {id: 'all_lists', name: 'dashboard.sidebar.all_lists'}
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapGetters([
      'getMobileShowFilterLists'
    ]),
    ...mapMutations([
      'setMobileShowFilterLists'
    ]),
    setActiveCallFilter(filter) {
      this.activeCallFilter = filter
    },
    setActiveListFilter(filter) {
      this.activeListFilter = filter
    },
    handleResize() {
      if(window.innerWidth > 1100) {
        this.setMobileShowFilterLists(false)
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/partials/_content";
</style>
