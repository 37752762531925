const VUEX = 'vuex'
const ALL_CALLS = 'all_calls'
const ALL_LISTS = 'dashboard.sidebar.all_lists'
const MONTHS = {
	0: 'january',
	1: 'february',
	2: 'march',
	3: 'april',
	4: 'may',
	5: 'june',
	6: 'july',
	7: 'august',
	8: 'september',
	9: 'october',
	10: 'november',
	11: 'december'
}
const CALLS = [
    { filter: 'all_calls', label: 'dashboard.sidebar.all_calls' },
	{ filter: 'call', label: 'dashboard.sidebar.phone_calls' },
	{ filter: 'event', label: 'dashboard.sidebar.events' }
]

export { VUEX, MONTHS, ALL_CALLS, ALL_LISTS, CALLS }