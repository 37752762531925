<template>
  <div :class="['recordings-sidebar', {'mobile-hide': !getMobileShowFilterLists()}]">
    <div class="sidebar-filters">
      <img src="@/assets/images/close.svg" alt="Close" id="close-lists" @click="setMobileShowFilterLists(false)">
      <span class="sidebar-title">{{ $t('dashboard.header.calls') }}</span>
      <span v-for="call in calls" :key="call.filter" @click="activeCallFilter = call.filter;$emit('callFilter', call.filter)" :class="{'active': activeCallFilter === call.filter }">{{ $t(call.label) }}</span>
      <hr>
      <span class="sidebar-title">{{ $t('dashboard.sidebar.lists') }}</span>
      <span v-for="list in lists" :key="list.id" @click="activeListFilter = list.name;$emit('listFilter', list)" :class="{'active': activeListFilter === list.name }">{{
      list.name === 'dashboard.sidebar.all_lists' ?  $t(list.name) : list.name }}</span>
    </div>

    <div class="tabs">
      <button class="tab create delete" v-if="lists.length > 0 && activeListFilter !== 'dashboard.sidebar.all_lists'" @click="deleteListMethod()">{{ $t('dashboard.sidebar.delete_list')}}</button>
      <button class="tab" v-if="lists.length > 0 && activeListFilter !== 'dashboard.sidebar.all_lists'" @click="editList();updatingList = true">{{ $t('dashboard.sidebar.edit_list')}}</button>
      <button class="tab create" @click="creatingList = true">{{ $t('dashboard.sidebar.create_new_list')}}</button>
    </div>

    <Modal v-if="creatingList || updatingList" :className="'align-left'">
      <template v-slot:header>
        <div class="create-new-list">
          <h1 v-if="creatingList">{{ $t('dashboard.sidebar.create_a_list') }}</h1>
          <h1 v-else>{{ $t('dashboard.sidebar.update_list') }}</h1>
          <hr>
          <p>{{ $t('dashboard.sidebar.list_name') }}</p>
          <input type="text" v-model="listName" :placeholder="$t('dashboard.sidebar.new_list')">
          <p class="error" v-if="errorCreatingList">{{ $t('dashboard.sidebar.list_exists') }}</p>
          <div class="actions">
            <p @click="creatingList = false; updatingList = false">{{ $t('dashboard.sidebar.cancel') }}</p>
            <button class="create" @click="createNewList('updating')" v-if="updatingList">{{ $t('dashboard.sidebar.update_list') }}</button>
            <button class="create" @click="createNewList('new')" v-if="creatingList">{{ $t('dashboard.sidebar.create_a_list') }}</button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import {CALLS} from '../assets/constants'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Modal from '@/components/ModalComponent'

export default {
  name: 'RecordingsSidebar',
  components: {
    Modal
  },
  data() {
    return {
      activeCallFilter: 'all_calls',
      activeListFilter: 'dashboard.sidebar.all_lists',
      loadedLists: false,
      calls: [],
      errorCreatingList: false,
      creatingList: false,
      updatingList: false,
      inProgress: false,
      updatingListItem: null,
      listName: ''
    }
  },
  computed: {
    lists() {
      return this.getLists()
    }
  },
  mounted() {
    this.calls = CALLS
    this.fetchLists().then(() => {
      this.loadedLists = true
    })
  },
  methods: {
    ...mapActions([
      'addNewList',
      'fetchLists',
      'updateLists',
      'deleteList'
    ]),
    ...mapGetters([
      'getLists',
      'getMobileShowFilterLists'
    ]),
    ...mapMutations([
      'setLists',
      'setMobileShowFilterLists'
    ]),
    deleteListMethod() {
      let deleteListEntry = this.lists.filter((entry) => entry.name === this.activeListFilter)
      if(deleteListEntry.length > 0) {
        this.deleteList(deleteListEntry[0].id).then(() => {
          this.reset()
          this.activeListFilter = 'dashboard.sidebar.all_lists'
        }).catch((error) => {
          console.error('Delete list', error)
        })
      }
    },
    editList() {
      this.updatingListItem = this.lists.filter((entry) => entry.name === this.activeListFilter)
      this.listName = this.updatingListItem[0].name
    },
    createNewList(action) {   
      if(this.inProgress) return

      let newName = this.listName.toLowerCase()
      let existing = this.lists.filter((entry) => entry.name.toLowerCase() === newName)

      if(existing.length === 0) {
        this.inProgress = true
        if(action === 'updating') {
          let updatedList = {
            id: this.updatingListItem[0].id,
            name: this.listName
          }
          this.updateLists(updatedList).then(() => {
            this.activeListFilter = this.listName
            this.reset()
          }).catch((error) => {
            this.inProgress = false
            console.error('Error updating lists', error)
          })

        } else {
          this.addNewList(this.listName).then(() => {
            this.reset()
          }).catch((error) => {
            this.inProgress = false
            console.error('Error adding new list', error)
          })
        }

      } else {
        this.errorCreatingList = true
      }
    },
    reset() {
      this.listName = ''
      this.creatingList = false
      this.updatingList = false
      this.updatingListItem = null
      this.inProgress = false
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/partials/_recordings-sidebar";
</style>
